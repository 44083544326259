<script setup>
// layout
import Layout from "../../layouts/main.vue";
import toastr from "toastr";
import mushroom from "cem-primary-api";
import MethodService from "../../service/MethodService";
import Data from "./dataFormKPI";
import SelectIconsColumns from "../../components/SelectIconsColumns.vue";
import { FormInstance } from "element-plus";
import Validate from "../../service/ValidService";
import { useRouter, useRoute } from "vue-router";
import { langWeb } from "../../stores/lang";
import { onMounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import LanguageService from "../../service/LanguageService";

const router = useRouter();
const route = useRoute();
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());

const listIconsColumns = ref([]);
const keySelectIcon = ref(0);
const loadingBtn = ref(false);
const validateFlag = ref(false);
const dataNguong = reactive({
  value: [
    {
      min: undefined,
      max: undefined,
      color: undefined,
      action: "add",
    },
  ],
});
const dataLinkParam = reactive({
  value: [
    {
      code: undefined,
      value: undefined,
      action: "add",
    },
  ],
});
const listDichVu = ref([]);
const listTableScreen = ref([]);
const errorColor = ref([]);
const errorMin = ref([]);
const errorMax = ref([]);
const errorCode = ref([]);
const errorCodeDuplicate = ref([]);
const errorCodeDefault = ref([]);
const errorValue = ref([]);
const errorCodeRegex = ref([]);
const targetDomainsSelect = Data.targetDomains;
const targetTypeSelect = Data.targetType;
const listUsecase = ref([]);
const listDefaultParam = [
  "id",
  "kpi_id",
  "kpi_code",
  "kpi_value",
  "start_date",
  "stop_date",
  "vlan",
  "search_key",
  "limit",
  "offset",
  "filters",
  "fields",
  "sort",
  "showFormSearch",
  "quality_levels",
  "uc_type",
  "page",
  "menu",
  "access_token",
  "pid",
  "urlPrimary",
  "nameProbe",
  "type_login",
];
const listDynamic = ref([]);

const formatTableColumnsValue = reactive({
  value: MethodService.copyObject(Data.formatColumnsTable),
});
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const ruleFormRef = ref(FormInstance);

const checkDomain = (rule, value, callback) => {
  if (!value && dataForm.value.target_type) {
    callback(new Error(t("valid_requiredChange")));
  } else {
    callback();
  }
};

const rulesForm = reactive({
  code: [Validate.required, Validate.checkMaxLength(50)],
  label: [Validate.required, Validate.checkMaxLength(50)],
  index: [Validate.checkNumberInteger],
  target_id: [Validate.requiredChange],
  target_domain: [{ validator: checkDomain, trigger: "change" }],
});

const getKpiId = async () => {
  try {
    const response = await mushroom.service_health_kpi.findByIdAsync({
      id: route.params.id,
    });
    dataForm.value = response.result;
    // if(response.result?.target_id) getServiceById(response.result.target_id);
    let listDetailNguong = [];
    let listLinkParam = [];
    if (response.result.value_settings) {
      response.result.value_settings.forEach((item) => {
        listDetailNguong.push({
          min: item.min,
          max: item.max,
          color: item.color,
          action: "",
        });
      });
      if (route.name === "ServiceHealthUpdate")
        listDetailNguong.push({
          min: undefined,
          max: undefined,
          color: undefined,
          action: "add",
        });
      dataNguong.value = listDetailNguong;
    }
    if (response.result.link_parameters) {
      response.result.link_parameters.forEach((item) => {
        listLinkParam.push({
          code: item.code,
          value: decodeURIComponent(item.value),
          action: "",
        });
      });
      if (route.name === "ServiceHealthUpdate")
        listLinkParam.push({
          code: undefined,
          value: undefined,
          action: "add",
        });
      dataLinkParam.value = listLinkParam;

      dataForm.value.link = formatLink(dataForm.value.link);
    }
    if (response.result.icon_id)
      formatTableColumnsValue.value.icon.file_id = response.result.icon_id;

    if (response.result.target_type === "service") await getListDichVu();
    if (response.result.target_type === "table_screen")
      await getListTableScreen();
    if (response.result.target_type === "dynamic") await getListDynamic();
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};

const formatLink = (value) => {
  const link = new URL(
    "http://test/" + (value[0] === "/" ? value.slice(1) : value)
  );
  if (link.search.length > 0) {
    const str = new URLSearchParams(link.search);
    dataLinkParam.value.forEach((item) => {
      if (item.code) {
        str.delete(item.code);
      }
    });
    return (
      link.pathname + (str.toString() ? "?" + str.toString() : "") + link.hash
    );
  }
  return value;
};

const uploadIconColumns = async () => {
  try {
    let nameImage = document.getElementById("uploadLogo").files[0]?.name;
    let typeImage = nameImage?.slice(nameImage.indexOf(".")).toLowerCase();

    if (typeImage) {
      if (
        typeImage !== ".jpg" &&
        typeImage !== ".png" &&
        typeImage !== ".jpeg" &&
        typeImage !== ".svg" &&
        typeImage !== ".ico"
      ) {
        toastr.warning(t("t_select_format_image"));
      } else {
        let res = await mushroom.$file.uploadAsync({
          file: document.getElementById("uploadLogo").files[0],
        });
        if (res.result) {
          formatTableColumnsValue.value.icon.file_id = res.result;
          // updateIconId(res.result);
          getIconForColumn(res.result);

          keySelectIcon.value += 1;
        }
      }
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const getIconForColumn = async (id) => {
  formatTableColumnsValue.value.icon.urlIcon = mushroom.$file.linkBuilder.thumb
    .id(id)
    .build();
};

const getListIconsColumns = async () => {
  try {
    const response = await mushroom.icon.getAllAsync();
    if (response.result?.length > 0)
      listIconsColumns.value = response.result.map((item) => item.file_id);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const renderIconColumns = (iconId) => {
  if (!iconId) return;
  return mushroom.$file.linkBuilder.thumb.id(iconId).build();
};

const setIcon = (fileId) => {
  formatTableColumnsValue.value.icon.file_id = fileId;
  getIconForColumn(fileId);
};

const openUploadFile = () => {
  if (route.name !== "ServiceHealthDetail") keySelectIcon.value += 1;
};

const changeColor = (value, index) => {
  if (value) {
    errorColor.value[index] = undefined;
  } else {
    errorColor.value[index] = index;
  }
};

const changeNumber = (min, max, index, label) => {
  if (Number(min) < Number(max) || (!min && min !== 0) || (!max && max !== 0)) {
    errorMin.value[index] = undefined;
    errorMax.value[index] = undefined;
  } else {
    if (label === "min") {
      errorMin.value[index] = index;
    }
    if (label === "max") {
      errorMax.value[index] = index;
    }
  }
};

const checkValidCode = (code, re, index) => {
  if (code && re.test(code)) {
    errorCode.value[index] = undefined;
    errorCodeRegex.value[index] = undefined;
  } else if (code && !re.test(code)) {
    errorCode.value[index] = undefined;
    errorCodeRegex.value[index] = index;
  } else {
    errorCodeRegex.value[index] = undefined;
    errorCode.value[index] = index;
  }
};

const checkValueCode = (code, index) => {
  const listCode = dataLinkParam.value
    .filter((item, count) => item.code && count !== index)
    .map((item) => item.code);
  if (listCode.includes(code)) {
    errorCodeDuplicate.value[index] = index;
  } else {
    errorCodeDuplicate.value[index] = undefined;
  }

  if (listDefaultParam.includes(code)) {
    errorCodeDefault.value[index] = index;
  } else {
    errorCodeDefault.value[index] = undefined;
  }
};

const changeParam = (code, value, index, label) => {
  if (label === "code") {
    checkValueCode(code, index);
    const re = /^([a-zA-Z0-9_]+)$/;
    checkValidCode(code, re, index);
  }
  if (label === "value") {
    dataLinkParam.value[index].value =
      value && value.trim().length > 0 ? value : undefined;
    errorValue.value[index] = dataLinkParam.value[index].value
      ? undefined
      : index;
  }
};

const getListDichVu = async () => {
  listDichVu.value = [];
  try {
    const responseRoles = JSON.parse(localStorage.getItem("userInfo"));
    const response = await mushroom.service.getAllAsync({
      fields: "id,type,name",
      filters: responseRoles.result.roles.includes("Admin")
        ? ["disabled!=true", "is_menu_hidden!=true"]
        : ["is_menu_hidden!=true"],
    });
    if (response.result) {
      if (dataForm.value.target_domain === "probe") {
        listDichVu.value = response.result.filter(
          (item) => item.type === "probe"
        );
      } else if (dataForm.value.target_domain === "bras") {
        listDichVu.value = response.result.filter(
          (item) => item.type === "bras"
        );
      } else listDichVu.value = response.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getListTableScreen = async () => {
  try {
    const response = await mushroom.table_screen.getAllAsync({
      fields: "id,type,name",
    });
    if (response.result) {
      if (dataForm.value.target_domain === "probe") {
        listTableScreen.value = response.result.filter(
          (item) => item.type === "probe"
        );
      } else if (dataForm.value.target_domain === "bras") {
        listTableScreen.value = response.result.filter(
          (item) => item.type === "bras"
        );
      } else listTableScreen.value = response.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getListMenu = async (ids) => {
  try {
    const response = await mushroom.menu.getAllAsync({
      filters: [`id:in:${ids}`],
    });
    if (!response.result) return;
    const list = listDynamic.value.map((item) => {
      const menu = response.result.find((x) => x.id === item.menu_id);
      if (menu) {
        return {
          id: item.id,
          menu_id: item.menu_id,
          name: menu.label,
        };
      } else {
        return {
          id: item.id,
          menu_id: item.menu_id,
          name: null,
        };
      }
    });
    listDynamic.value = list;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getListDynamic = async () => {
  try {
    const response = await mushroom.dynamic_dashboard.getAllAsync();
    if (!response.result) return;
    listDynamic.value = response.result;
    const listMenuId = response.result
      .map((item) => item.menu_id)
      .filter((item) => item);
    if (listMenuId.length > 0) await getListMenu(listMenuId);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const changeTargetDomain = async () => {
  dataForm.value.target_id = undefined;
  await getListDichVu();
  await getListTableScreen();
};

const getListUsecase = async () => {
  try {
    const response = await mushroom.usecase.getAllAsync({
      fields: "id,name,code",
    });
    if (response.result)
      response.result.forEach((item) => {
        listUsecase.value.push({
          value: item.id,
          label: item.name,
          code: item.code,
        });
      });
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const changeTargetType = async (e) => {
  if (!e) dataForm.value.target_type = undefined;
  dataForm.value.target_id = undefined;
  dataForm.value.link = undefined;
  if (e === "service") await getListDichVu();
  if (e === "table_screen") await getListTableScreen();
  if (e === "dynamic") await getListDynamic();
};

const addNguong = (row, index) => {
  if (
    row.color &&
    (Number(row.min) < Number(row.max) || !row.min || !row.max)
  ) {
    errorColor.value[index] = undefined;
    const length = dataNguong.value.length;
    dataNguong.value[length - 1] = {
      min: row.min,
      max: row.max,
      color: row.color,
      action: "",
    };
    dataNguong.value.push({
      min: undefined,
      max: undefined,
      color: undefined,
      action: "add",
    });
  } else {
    if (!row.color) errorColor.value[index] = index;
  }
};

const addParam = (row, index) => {
  const re = /^([a-zA-Z0-9_]+)$/;
  const listCode = dataLinkParam.value
    .filter((item, count) => item.code && count !== index)
    .map((item) => item.code);
  if (
    row.code &&
    row.value &&
    row.value.trim().length > 0 &&
    re.test(row.code) &&
    !listCode.includes(row.code) &&
    !listDefaultParam.includes(row.code)
  ) {
    const length = dataLinkParam.value.length;
    dataLinkParam.value[length - 1] = {
      code: row.code,
      value: row.value,
      action: "",
    };
    dataLinkParam.value.push({
      code: undefined,
      value: undefined,
      action: "add",
    });
  } else {
    checkValueCode(row.code, index);
    checkValidCode(row.code, re, index);
    errorValue.value[index] =
      row.value && row.value.trim().length > 0 ? undefined : index;
  }
};

const deleteParam = (index) => {
  dataLinkParam.value.splice(index, 1);
  errorCode.value[index] = undefined;
  errorValue.value[index] = undefined;
  errorCodeRegex.value[index] = undefined;
  errorCodeDuplicate.value[index] = undefined;
  errorCodeDefault.value[index] = undefined;
};

const deleteNguong = (index) => {
  dataNguong.value.splice(index, 1);
  errorColor.value[index] = undefined;
  errorMin.value[index] = undefined;
  errorMax.value[index] = undefined;
};

const renderLink = async (type, id) => {
  let link = "";
  if (type === "service") link = "/" + "services/" + id;
  if (type === "table_screen") link = "/" + "table-screen/" + id;
  if (type === "usecase") {
    const response = await mushroom.usecase.findByIdAsync({
      id: id,
      fields: "code",
    });
    if (response.result)
      link = "/" + "usecases/" + response.result.code.toLowerCase() + "/" + id;
  }
  if (type === "dynamic") link = "/" + "dashboard/" + id;

  return link;
};

const setParams = (link, params) => {
  const url = new URL(
    "http://test/" + (link[0] === "/" ? link.slice(1) : link)
  );
  if (!url.search && !url.hash && params.length > 0 && link !== "#") {
    return `${url.pathname}?${params.join("&")}`;
  } else if (url.search && url.hash && params.length > 0) {
    return url.pathname + url.search + "&" + params.join("&") + url.hash;
  } else if (url.hash && params.length > 0) {
    return url.pathname + "?" + params.join("&") + url.hash;
  } else if (url.search && params.length > 0) {
    return url.pathname + url.search + "&" + params.join("&");
  } else if (link === "#" && params.length > 0) {
    return url.pathname + "?" + params.join("&");
  } else {
    return link;
  }
};

const addNewKPI = async (formEl) => {
  const checkColorNguong = dataNguong.value.filter(
    (item) => !item.color && item.action === ""
  ).length;
  let checkMinMax = false;
  dataNguong.value.forEach((item) => {
    if (item.min && item.max && Number(item.min) >= Number(item.max)) {
      checkMinMax = true;
    }
  });

  let checkParam = false;
  const re = /^([a-zA-Z0-9_]+)$/;
  dataLinkParam.value.forEach((item) => {
    if (
      item.action === "" &&
      (!item.code || !item.value || !re.test(item.code))
    )
      checkParam = true;
  });

  const listCodeDuplicate = Array.from(
    new Set(
      dataLinkParam.value
        .filter((item) => item.action === "")
        .map((item) => item.code)
    )
  );
  validateFlag.value = true;
  if (
    !formEl ||
    checkColorNguong > 0 ||
    checkMinMax ||
    checkParam ||
    dataLinkParam.value.some((item) => listDefaultParam.includes(item.code)) ||
    listCodeDuplicate.length !== dataLinkParam.value.length - 1
  )
    return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (valid) {
      try {
        const textLink = await renderLink(
          dataForm.value.target_type,
          dataForm.value.target_id
        );
        const response = await mushroom.service_health_kpi.listAsync({
          sort: "-index",
          limit: 1,
        });
        let index = 1;
        if (response.result && response.result[0].index) {
          index = response.result[0].index + 1;
        }
        const listValueSetting = dataNguong.value
          .filter((item) => item.action === "")
          .map((item) => {
            return {
              min: item.min && item.min != 0 ? Number(item.min) : null,
              max: item.max && item.max != 0 ? Number(item.max) : null,
              color: item.color,
            };
          });
        const listParam = dataLinkParam.value
          .filter((item) => item.action === "")
          .map((item) => {
            return {
              code: item.code ? item.code : null,
              value: item.value ? encodeURIComponent(item.value) : null,
            };
          });
        const strParam = listParam.map((item) => `${item.code}=${item.value}`);

        const service_health_kpi_object = {
          index: dataForm.value.index ? Number(dataForm.value.index) : index,
          code: dataForm.value.code,
          label: dataForm.value.label,
          unit: dataForm.value.unit ? dataForm.value.unit.trim() : undefined,
          visible: dataForm.value.visible ?? false,
          tooltip: dataForm.value.tooltip
            ? dataForm.value.tooltip.trim()
            : undefined,
          icon_id: formatTableColumnsValue.value.icon.file_id,
          value_settings: listValueSetting,
          target_domain: dataForm.value.target_domain,
          target_type: dataForm.value.target_type,
          target_id: dataForm.value.target_id,
          link:
            dataForm.value.target_type === "static" && dataForm.value.link
              ? setParams(dataForm.value.link, strParam)
              : dataForm.value.target_type === "static" && !dataForm.value.link
              ? setParams("/", strParam)
              : setParams(textLink, strParam),
          link_parameters: listParam,
        };
        console.log("dữ liệu truyền lên: ", service_health_kpi_object);
        try {
          const newId = await mushroom.service_health_kpi.createAsync(
            service_health_kpi_object
          );
          if (newId) {
            toastr.success(t("toastr_add_service_health_kpi_success"));
          }
          router.go(-1);
        } catch (error) {
          if (error?.meta?.field === "$unique_key") {
            toastr.error(t("toastr_code_exsited"));
          } else {
            toastr.error(t("toastr_add_service_health_kpi_fail"));
          }
        } finally {
          setTimeout(() => {
            loadingBtn.value = false;
          }, 1000);
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

const UpdateKPI = async (formEl) => {
  const checkColorNguong = dataNguong.value.filter(
    (item) => !item.color && item.action === ""
  ).length;
  let checkMinMax = false;
  dataNguong.value.forEach((item) => {
    if (item.min && item.max && Number(item.min) >= Number(item.max)) {
      checkMinMax = true;
    }
  });

  let checkParam = false;
  const re = /^([a-zA-Z0-9_]+)$/;
  dataLinkParam.value.forEach((item) => {
    if (
      item.action === "" &&
      (!item.code || !item.value || !re.test(item.code))
    )
      checkParam = true;
  });

  const listCodeDuplicate = Array.from(
    new Set(
      dataLinkParam.value
        .filter((item) => item.action === "")
        .map((item) => item.code)
    )
  );
  validateFlag.value = true;
  if (
    !formEl ||
    checkColorNguong > 0 ||
    checkMinMax ||
    checkParam ||
    dataLinkParam.value.some((item) => listDefaultParam.includes(item.code)) ||
    listCodeDuplicate.length !== dataLinkParam.value.length - 1
  )
    return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (valid) {
      const textLink = await renderLink(
        dataForm.value.target_type,
        dataForm.value.target_id
      );
      const listValueSetting = dataNguong.value
        .filter((item) => item.action === "")
        .map((item) => {
          return {
            min: item.min && item.min != 0 ? Number(item.min) : null,
            max: item.max && item.max != 0 ? Number(item.max) : null,
            color: item.color,
          };
        });
      const listParam = dataLinkParam.value
        .filter((item) => item.action === "")
        .map((item) => {
          return {
            code: item.code ? item.code : null,
            value: item.value ? encodeURIComponent(item.value) : null,
          };
        });

      const strParam = listParam.map((item) => `${item.code}=${item.value}`);

      const service_health_kpi_object = {
        id: route.params.id,
        index: dataForm.value.index ? Number(dataForm.value.index) : undefined,
        code: dataForm.value.code,
        label: dataForm.value.label,
        unit: dataForm.value.unit
          ? dataForm.value.unit.trim()
          : dataForm.value.unit === ""
          ? ""
          : undefined,
        visible: dataForm.value.visible ?? false,
        tooltip: dataForm.value.tooltip
          ? dataForm.value.tooltip.trim()
          : dataForm.value.tooltip === ""
          ? ""
          : undefined,
        icon_id: formatTableColumnsValue.value.icon.file_id,
        value_settings: listValueSetting,
        target_domain: dataForm.value.target_domain,
        target_type: dataForm.value.target_type,
        target_id: dataForm.value.target_id,
        link:
          dataForm.value.target_type === "static" && dataForm.value.link
            ? setParams(dataForm.value.link, strParam)
            : dataForm.value.target_type === "static" && !dataForm.value.link
            ? setParams("/", strParam)
            : setParams(textLink, strParam),
        link_parameters: listParam,
      };
      console.log("dữ liệu truyền lên: ", service_health_kpi_object);
      try {
        const response = await mushroom.service_health_kpi.partialUpdateAsync(
          service_health_kpi_object
        );
        if (response.result === route.params.id)
          toastr.success(t("toastr_edit_service_health_kpi_success"));
        else toastr.success(t("toastr_not_change_warning"));
        router.go(-1);
      } catch (error) {
        if (error?.meta?.field === "$unique_key") {
          toastr.error(t("toastr_code_exsited"));
        } else {
          toastr.error(t("toastr_edit_service_health_kpi_fail"));
        }
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    if (validateFlag.value == true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);

onMounted(async () => {
  await getListIconsColumns();
  await getListUsecase();
  if (
    route.name === "ServiceHealthDetail" ||
    route.name === "ServiceHealthUpdate"
  ) {
    await getKpiId();
  }
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="route.name === 'ServiceHealthAdd'">
          {{ $t("t-add-service-health-kpi") }}
        </h3>
        <h3 v-if="route.name === 'ServiceHealthDetail'">
          {{ $t("t-view-service-health-kpi") }}
        </h3>
        <h3 v-if="route.name === 'ServiceHealthUpdate'">
          {{ $t("t-edit-service-health-kpi") }}
        </h3>
      </div>
      <div class="card-body form-service-health-kpi">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            status-icon
            :rules="rulesForm"
            label-width="200px"
            class="form-user"
          >
            <el-form-item :label="$t('t-service-health-kpi-code')" prop="code">
              <el-input
                type="text"
                v-model="dataForm.value.code"
                autocomplete="off"
                :placeholder="$t('t-place-service-health-kpi-code')"
                :disabled="route.name === 'ServiceHealthDetail'"
                @change="dataForm.value.code = dataForm.value.code.trim()"
              />
            </el-form-item>
            <el-form-item :label="$t('t-service-health-kpi-name')" prop="label">
              <el-input
                type="text"
                v-model="dataForm.value.label"
                autocomplete="off"
                :placeholder="$t('t-place-service-health-kpi-name')"
                :disabled="route.name === 'ServiceHealthDetail'"
                @change="dataForm.value.label = dataForm.value.label.trim()"
              />
            </el-form-item>

            <el-form-item
              :label="$t('t-service-health-kpi-index')"
              prop="index"
            >
              <el-input
                type="number"
                v-model="dataForm.value.index"
                autocomplete="off"
                :placeholder="$t('t-place-service-health-kpi-index')"
                :disabled="route.name === 'ServiceHealthDetail'"
              />
            </el-form-item>

            <el-form-item :label="$t('t-service-health-kpi-unit')">
              <el-input
                type="text"
                v-model="dataForm.value.unit"
                autocomplete="off"
                :placeholder="$t('t-place-service-health-kpi-unit')"
                :disabled="route.name === 'ServiceHealthDetail'"
              />
            </el-form-item>

            <el-form-item :label="$t('t-tooltip')">
              <el-input
                type="text"
                v-model="dataForm.value.tooltip"
                autocomplete="off"
                :placeholder="$t('t-place-tooltip')"
                :disabled="route.name === 'ServiceHealthDetail'"
              />
            </el-form-item>

            <b-row>
              <b-col md="8">
                <el-form-item
                  :label="$t('t-select-icon')"
                  class="d-flex icon-table"
                >
                  <div class="icon-columns">
                    <img
                      v-if="
                        formatTableColumnsValue.value.icon.file_id?.length > 0
                      "
                      :src="
                        renderIconColumns(
                          formatTableColumnsValue.value.icon.file_id
                        )
                      "
                      alt=""
                    />
                    <img v-else src="@/assets/icon/none-icon-svg.svg" alt="" />
                  </div>
                  <div>
                    <select-icons-columns
                      :listIcons="listIconsColumns"
                      @setIcon="setIcon"
                      :key="keySelectIcon"
                      :disabled="route.name === 'ServiceHealthDetail'"
                    ></select-icons-columns>
                  </div>
                  <!-- nút tải lên -->
                  <span style="margin: 0 5px">{{ $t("t-or") }}</span>
                  <div class="" @click="openUploadFile">
                    <label
                      for="uploadLogo"
                      class="label-upload-icons"
                      :class="{
                        disableUploadIcon: route.name === 'ServiceHealthDetail',
                      }"
                    >
                      <img src="@/assets/icon/upload_white.svg" alt="upload" />
                      <span>{{ $t("t-upload") }}</span>
                    </label>
                  </div>

                  <input
                    type="file"
                    accept=".jpg, .png, .jpeg, .svg, .JPEG, .ico"
                    id="uploadLogo"
                    style="display: none"
                    @change="uploadIconColumns"
                  />
                </el-form-item>
              </b-col>
              <b-col md="4">
                <el-checkbox
                  v-model="dataForm.value.visible"
                  :label="$t('t-display')"
                  :disabled="route.name === 'ServiceHealthDetail'"
                />
              </b-col>
            </b-row>

            <el-form-item :label="$t('t-target-domains')" prop="target_domain">
              <el-select
                v-model="dataForm.value.target_domain"
                filterable
                clearable
                :placeholder="$t('t-placeholder-target-domains')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                @change="changeTargetDomain"
                :disabled="route.name === 'ServiceHealthDetail'"
              >
                <el-option
                  v-for="item in targetDomainsSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('t-target-type')" prop="target_type">
              <el-select
                v-model="dataForm.value.target_type"
                filterable
                :placeholder="$t('t-placeholder-target-type')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                @change="changeTargetType"
                :disabled="route.name === 'ServiceHealthDetail'"
                clearable
              >
                <el-option
                  v-for="item in targetTypeSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t('t-link')"
              v-if="
                dataForm.value.target_type === 'static' ||
                dataForm.value.target_type === undefined
              "
            >
              <el-input
                v-model="dataForm.value.link"
                :placeholder="$t('t-place-service-health-kpi-link')"
                :disabled="route.name === 'ServiceHealthDetail'"
              ></el-input>
            </el-form-item>

            <el-form-item :label="$t('t-target')" prop="target_id" v-else>
              <el-select
                filterable
                v-if="dataForm.value.target_type === 'service'"
                v-model="dataForm.value.target_id"
                :placeholder="$t('t-select-service')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                :disabled="route.name === 'ServiceHealthDetail'"
              >
                <el-option
                  v-for="item in listDichVu"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-select
                filterable
                v-if="dataForm.value.target_type === 'table_screen'"
                v-model="dataForm.value.target_id"
                :placeholder="$t('t-select-table-screen')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in listTableScreen"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-select
                filterable
                v-if="dataForm.value.target_type === 'usecase'"
                v-model="dataForm.value.target_id"
                :placeholder="$t('t-select-usecase')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in listUsecase"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                filterable
                v-if="dataForm.value.target_type === 'dynamic'"
                v-model="dataForm.value.target_id"
                :placeholder="$t('t-select-dynamic')"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
                :disabled="route.name === 'ServiceHealthDetail'"
              >
                <el-option
                  v-for="item in listDynamic"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('t-params-list')" class="nguong-mau-sac">
              <el-table :data="dataLinkParam.value">
                <el-table-column prop="code" min-width="100" align="start">
                  <template #header>
                    <div class="info-param">
                      <span>Variable</span>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        placement="top-start"
                        popper-class="tooltip-param"
                      >
                        <template #content>
                          {{
                            $t("t_variations_conditions", {
                              list: listDefaultParam.join(`, `),
                            })
                          }}
                        </template>
                        <i class="ri-question-line me-1 align-bottom"></i>
                      </el-tooltip>
                    </div>
                  </template>
                  <template #default="scope">
                    <el-input
                      type="text"
                      v-model="scope.row.code"
                      placeholder="Variable"
                      :disabled="route.name === 'ServiceHealthDetail'"
                      :class="{
                        error_number:
                          errorCode[scope.$index] === scope.$index ||
                          errorCodeRegex[scope.$index] === scope.$index ||
                          errorCodeDuplicate[scope.$index] === scope.$index ||
                          errorCodeDefault[scope.$index] === scope.$index,
                      }"
                      @change="
                        changeParam(
                          scope.row.code,
                          scope.row.value,
                          scope.$index,
                          'code'
                        )
                      "
                    />
                    <span
                      v-if="errorCode[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_required") }}</span
                    >
                    <span
                      v-if="errorCodeRegex[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_checkCode") }}</span
                    >
                    <span
                      v-if="errorCodeDuplicate[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_exist_variable") }}</span
                    >
                    <span
                      v-if="errorCodeDefault[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_not_allowed_variable") }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="value"
                  label="Value"
                  min-width="100"
                  align="start"
                >
                  <template #default="scope">
                    <el-input
                      type="text"
                      v-model="scope.row.value"
                      placeholder="Value"
                      :disabled="route.name === 'ServiceHealthDetail'"
                      :class="{
                        error_number: errorValue[scope.$index] === scope.$index,
                      }"
                      @change="
                        changeParam(
                          scope.row.code,
                          scope.row.value,
                          scope.$index,
                          'value'
                        )
                      "
                    />
                    <span
                      v-if="errorValue[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_required") }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="route.name !== 'ServiceHealthDetail'"
                  :label="$t('t-action')"
                  align="center"
                  width="100px"
                >
                  <template #default="scope">
                    <div class="btn-group-thao-tac">
                      <el-tooltip
                        :content="$t('t-add')"
                        effect="light"
                        placement="top"
                        v-if="scope.row.action === 'add'"
                      >
                        <button
                          type="button"
                          class="btn btn-soft-info waves-effect waves-light btn-sm"
                          @click="addParam(scope.row, scope.$index)"
                        >
                          <i class="ri-add-circle-line"></i>
                        </button>
                      </el-tooltip>
                      <el-tooltip
                        :content="$t('t-delete')"
                        effect="light"
                        placement="top"
                        v-else
                      >
                        <button
                          type="button"
                          class="btn btn-soft-danger waves-effect waves-light btn-sm"
                          @click="deleteParam(scope.$index)"
                        >
                          <i class="ri-delete-bin-6-line"></i>
                        </button>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-form-item :label="$t('t-threshold')" class="nguong-mau-sac">
              <el-table :data="dataNguong.value">
                <el-table-column
                  prop="min"
                  :label="$t('t-start-color')"
                  min-width="100"
                  align="start"
                >
                  <template #default="scope">
                    <el-input
                      type="number"
                      v-model="scope.row.min"
                      :placeholder="$t('t-start-color')"
                      :disabled="route.name === 'ServiceHealthDetail'"
                      :class="{
                        error_number: errorMin[scope.$index] === scope.$index,
                      }"
                      @change="
                        changeNumber(
                          scope.row.min,
                          scope.row.max,
                          scope.$index,
                          'min'
                        )
                      "
                    />
                    <span
                      v-if="errorMin[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_small_to_large") }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="max"
                  :label="$t('t-end-color')"
                  min-width="100"
                  align="start"
                >
                  <template #default="scope">
                    <el-input
                      type="number"
                      v-model="scope.row.max"
                      :placeholder="$t('t-end-color')"
                      :disabled="route.name === 'ServiceHealthDetail'"
                      :class="{
                        error_number: errorMax[scope.$index] === scope.$index,
                      }"
                      @change="
                        changeNumber(
                          scope.row.min,
                          scope.row.max,
                          scope.$index,
                          'max'
                        )
                      "
                    />
                    <span
                      v-if="errorMax[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_small_to_large") }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="color"
                  :label="$t('t-colors')"
                  min-width="150"
                  align="start"
                >
                  <template #default="scope">
                    <div>
                      <el-input
                        v-model="scope.row.color"
                        placeholder=""
                        style="width: 70%; padding-right: 5px"
                        :disabled="route.name === 'ServiceHealthDetail'"
                        :class="{
                          error_number:
                            errorColor[scope.$index] === scope.$index,
                        }"
                        @change="changeColor(scope.row.color, scope.$index)"
                      />
                      <el-color-picker
                        v-model="scope.row.color"
                        :disabled="route.name === 'ServiceHealthDetail'"
                        @change="changeColor(scope.row.color, scope.$index)"
                      />
                    </div>
                    <span
                      v-if="errorColor[scope.$index] === scope.$index"
                      class="error"
                      >{{ $t("valid_choose_color") }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="route.name !== 'ServiceHealthDetail'"
                  :label="$t('t-action')"
                  align="center"
                  width="100px"
                >
                  <template #default="scope">
                    <div class="btn-group-thao-tac">
                      <el-tooltip
                        :content="$t('t-add')"
                        effect="light"
                        placement="top"
                        v-if="scope.row.action === 'add'"
                      >
                        <button
                          type="button"
                          class="btn btn-soft-info waves-effect waves-light btn-sm"
                          @click="addNguong(scope.row, scope.$index)"
                        >
                          <i class="ri-add-circle-line"></i>
                        </button>
                      </el-tooltip>
                      <el-tooltip
                        :content="$t('t-delete')"
                        effect="light"
                        placement="top"
                        v-else
                      >
                        <button
                          type="button"
                          class="btn btn-soft-danger waves-effect waves-light btn-sm"
                          @click="deleteNguong(scope.$index)"
                        >
                          <i class="ri-delete-bin-6-line"></i>
                        </button>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="router.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <el-button
                type="button"
                style="height: 40px"
                class="btn btn-secondary btn-border"
                @click="addNewKPI(ruleFormRef)"
                v-if="route.name === 'ServiceHealthAdd'"
                :loading="loadingBtn"
              >
                {{ $t("t-add") }}
              </el-button>
              <el-button
                type="button"
                style="height: 40px"
                class="btn btn-secondary btn-border"
                @click="UpdateKPI(ruleFormRef)"
                v-if="route.name === 'ServiceHealthUpdate'"
                :loading="loadingBtn"
              >
                {{ $t("t-update") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
<link type="scss" src="./service-health-kpi.scss" />
